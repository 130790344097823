import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router } from '@reach/router';

import { PlanRouter } from '../components/router';
import { DetalleTareaLayout } from '../layouts';
import { api } from '../services';
import { useRedirectDetailTask } from '../hooks';
import { setUnlockedSteps } from '../store/actions/step';

const TareaPage = props => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const currentLang = useSelector(({ config }) => config.language);
  const currentToken = useSelector(({ login }) => login.token);
  const proRole = useSelector(({ login }) =>
    login.user === 'Premium Admin' || login.user === 'Premium' ? true : false
  );
  const dispatch = useDispatch();
  const { fetchDetailTaskData, updateMyProgress } = api();
  const stepStore = useSelector(({ step }) => step);
  const progress = useSelector(({ step }) => step.unlockedSteps);

  useRedirectDetailTask(props.id);

  const addTask = (stepId, numberTask) => {
    // Array of task unlocks
    const unlockedTasks = stepStore.unlockedSteps
      .map(step => step.tasks)
      .reduce((obj, val) => [...obj, ...val], []);
    // Check if step is unlock
    const isUnlocked = unlockedTasks.includes(parseInt(numberTask, 10));
    if (!isUnlocked) {
      const lastTask = Math.max(...unlockedTasks);
      if (numberTask && lastTask + 1 === numberTask) {
        dispatch(
          setUnlockedSteps(parseInt(stepId, 10), parseInt(numberTask, 10))
        );
      }
    }
  };
  useEffect(() => {
    updateMyProgress(currentToken, progress);
  }, [progress]);

  useEffect(() => {
    let ignore = false;
    const fetchData = async ignore => {
      try {
        let response = null;
        response = await fetchDetailTaskData(
          props.id,
          currentLang,
          parseInt(props.id, 10) + 1
        );

        if (!ignore) {
          setData(response);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData(ignore);
    return () => {
      ignore = true;
    };
  }, [currentLang, props, fetchDetailTaskData]);

  // para agregar la tarea desbloqueada
  useEffect(() => {
    if (!loading && data) {
      addTask(data.content.stepId, data.content.numberTask);
      // Agrega la siguiente tarea como desbloqueada
      if (data.nextTask)
        addTask(data.nextTask.stepId, data.nextTask.numberTask);
    }
  }, [data, loading, addTask]);

  return (
    <Router>
      <PlanRouter
        path="/tarea/:id/detalle"
        component={DetalleTareaLayout}
        id={props.id}
        data={data}
        loading={loading}
        error={error}
        currentLang={currentLang}
        proRole={proRole}
      />
    </Router>
  );
};

export default TareaPage;
